import React from "react";
import { useTranslation } from "react-i18next";
import "./Css/Publishing.css";

export interface IPublishingProps {}

const Publishing: React.FC<IPublishingProps> = (props) => {
    const { t } = useTranslation();

    return (
        <div className="publishing-view">
            <div className="publishing-container">
                <div className="publishing-data-row">
                    <div className="name">XANGA Ventures Zrt. 2023. évi éves beszámoló</div>
                    <a
                        href="/XANGA_Ventures_Zrt_2023_evi_eves_beszamolo.pdf"
                        target="_blank"
                        className="a-button"
                        rel="noreferrer noopener"
                    >
                        {t("view")}
                    </a>
                </div>
                <div className="publishing-data-row">
                    <div className="name">
                        Cégjegyzékben nyilvántartott adatok változásának közzététele - 2023.06.14.
                    </div>
                    <a
                        href="/Cegjegyzekben_nyilvantartott_adatok_valtozasanak_kozzetetele_2023.06.14.pdf"
                        target="_blank"
                        className="a-button"
                        rel="noreferrer noopener"
                    >
                        {t("view")}
                    </a>
                </div>
                <div className="publishing-data-row">
                    <div className="name">XANGA Ventures Zrt. 2022. évi éves beszámoló</div>
                    <a
                        href="/XANGA_Ventures_Zrt_2022_evi_eves_beszamolo.pdf"
                        target="_blank"
                        className="a-button"
                        rel="noreferrer noopener"
                    >
                        {t("view")}
                    </a>
                </div>
                <div className="publishing-data-row">
                    <div className="name">Nyilatkozat tulajdonosi struktúráról - 2022.12.31</div>
                    <a
                        href="/Nyilatkozat_tulajdonosi_strukturarol-2022.12.31.pdf"
                        target="_blank"
                        className="a-button"
                        rel="noreferrer noopener"
                    >
                        {t("view")}
                    </a>
                </div>
                <div className="publishing-data-row">
                    <div className="name">XANGA Ventures Zrt. 2021. évi éves beszámoló</div>
                    <a
                        href="/XANGA_Ventures_Zrt_2021_evi_eves_beszamolo.pdf"
                        target="_blank"
                        className="a-button"
                        rel="noreferrer noopener"
                    >
                        {t("view")}
                    </a>
                </div>
                <div className="publishing-data-row">
                    <div className="name">
                        Cégjegyzékben nyilvántartott adatok változásának közzététele – 2022.04.08.
                    </div>
                    <a
                        href="/Cegjegyzekben_nyilvantartott_adatok_valtozasanak_kozzetetele_2022_04_08.pdf"
                        target="_blank"
                        className="a-button"
                        rel="noreferrer noopener"
                    >
                        {t("view")}
                    </a>
                </div>
                <div className="publishing-data-row">
                    <div className="name">
                        Cégjegyzékben nyilvántartott adatok változásának közzététele – 2022.03.17.
                    </div>
                    <a
                        href="/KOZZETETEL_2022.03.17.pdf"
                        target="_blank"
                        className="a-button"
                        rel="noreferrer noopener"
                    >
                        {t("view")}
                    </a>
                </div>
                <div className="publishing-data-row">
                    <div className="name">
                        Cégjegyzékben nyilvántartott adatok változásának közzététele – 2021.07.09
                    </div>
                    <a
                        href="/Cegjegyzekben_nyilvantartott_adatok_valtozasanak_kozzetetele_20210709.pdf"
                        target="_blank"
                        className="a-button"
                        rel="noreferrer noopener"
                    >
                        {t("view")}
                    </a>
                </div>
                <div className="publishing-data-row">
                    <div className="name">XANGA Ventures Zrt. 2020. évi éves beszámoló</div>
                    <a
                        href="/XANGA_Ventures_Zrt_2020_evi_eves_beszamolo.pdf"
                        target="_blank"
                        className="a-button"
                        rel="noreferrer noopener"
                    >
                        {t("view")}
                    </a>
                </div>
                <div className="publishing-data-row">
                    <div className="name">
                        Cégjegyzékben nyilvántartott adatok változásának közzététele – 2020.09.21
                    </div>
                    <a
                        href="/Cegjegyzekben_nyilvantartott_adatok_valtozasanak_kozzetetele_20200921.pdf"
                        target="_blank"
                        className="a-button"
                        rel="noreferrer noopener"
                    >
                        {t("view")}
                    </a>
                </div>
                <div className="publishing-data-row">
                    <div className="name">XANGA Ventures Zrt 2019. évi beszámolója</div>
                    <a
                        href="/XANGA_Ventures_2019_evi_beszamolo.pdf"
                        target="_blank"
                        className="a-button"
                        rel="noreferrer noopener"
                    >
                        {t("view")}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Publishing;
